import React, { FunctionComponent } from "react";
import { Link, FormattedMessage } from "gatsby-plugin-react-intl";

import { LanguageMenu } from "./language-menu";

// TODO use CSS modules
import "./header.scss";

import silicoLogo from "../../assets/images/silico-logo.svg";
import bottomBorder from "../../assets/images/header-bottom-border.svg";
import downArrow from "../../assets/images/icons/down-arrow.svg";

const Header: FunctionComponent = () => {
  const scrollToContent = () => window.scrollBy(0, window.innerHeight / 1.6);
  const span = (text: string) => <span>{text}</span>;

  return (
    <header>
      <div className="cs_header-content-wrapper">
        <div className="cs_header-content">
          <div className="cs_header-content-item logo">
            <Link to="/">
              <img src={silicoLogo} alt="Silico logo" />
            </Link>
          </div>
          <div className="cs_header-content-item title">
            <FormattedMessage id="header.tagline" tagName="h1" values={{ span }} />
          </div>
        </div>
        <ul className="cs_header-navbar">
          <li className="cs_header-navbar-item-wrapper">
            <Link to="/" activeClassName="active">
              <FormattedMessage id="page.about" />
            </Link>
          </li>
          <li className="cs_header-navbar-item-wrapper">
            <Link to="/products" activeClassName="active">
              <FormattedMessage id="page.products" />
            </Link>
          </li>
          <li className="cs_header-navbar-item-wrapper">
            <Link to="/contact" activeClassName="active">
              <FormattedMessage id="page.contact" />
            </Link>
          </li>
        </ul>
        <div className="cs_header-lang">
          <LanguageMenu />
        </div>
      </div>
      <div className="cs_header-scroll-down-btn" onClick={scrollToContent}>
        <img className="cs_header-scroll-down-icon" src={downArrow} alt="" />
      </div>
      <div className="cs_header-pattern-wrapper">
        <div className="cs_header-pattern-fill" />
        <div className="cs_header-pattern-fill center">
          <img className="cs_header-bottom-pattern-image" src={bottomBorder} alt="" />
        </div>
        <div className="cs_header-pattern-fill" />
      </div>
      <div className="cs_header-pattern-wrapper overlay">
        <div className="cs_header-pattern-fill" />
        <div className="cs_header-pattern-fill center">
          <img className="cs_header-bottom-pattern-image" src={bottomBorder} alt="" />
        </div>
        <div className="cs_header-pattern-fill" />
      </div>
    </header>
  );
};

export default Header;
