import React, { FunctionComponent, useState } from "react";
import { changeLocale, useIntl } from "gatsby-plugin-react-intl";
import { Button, ListItemIcon, Menu, MenuItem } from "@material-ui/core";

import { menu, flag } from "./language-menu.module.scss";

import plFlag from "../../assets/images/flags/pl.svg";
import enFlag from "../../assets/images/flags/en.svg";
import deFlag from "../../assets/images/flags/de.svg";
import ruFlag from "../../assets/images/flags/ru.svg";

interface Language {
  locale: string;
  name: string;
  flag: string;
}

const SUPPORTED_LANGUAGES: Language[] = [
  { locale: "pl", name: "Polski", flag: plFlag },
  { locale: "en", name: "English", flag: enFlag },
  { locale: "de", name: "Deutsch", flag: deFlag },
  { locale: "ru", name: "Русский", flag: ruFlag },
];

export const LanguageMenu: FunctionComponent = () => {
  const { locale } = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => lang.locale === locale) as Language;

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const changeLanguage = (lang: Language) => {
    changeLocale(lang.locale);
    closeMenu();
  };

  return (
    <>
      <Button
        aria-controls="i18n-menu"
        aria-haspopup="true"
        onClick={openMenu}
        style={{ outline: "none", color: "white" }}
        variant="outlined"
        color="inherit"
      >
        <ListItemIcon className={flag}>
          <img src={selectedLanguage.flag} alt={selectedLanguage.name} />
        </ListItemIcon>
        {selectedLanguage.name}
      </Button>
      <Menu
        id="i18n-menu"
        className={menu}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {SUPPORTED_LANGUAGES.map((lang) => (
          <MenuItem key={lang.locale} onClick={() => changeLanguage(lang)}>
            <ListItemIcon className={flag}>
              <img src={lang.flag} alt={selectedLanguage.name} />
            </ListItemIcon>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
