import React, { FunctionComponent } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import * as classes from "./contact-form.module.scss";

const ContactForm: FunctionComponent = () => {
  return (
    <form method="post" action="https://getform.io/f/657b8dd5-0851-4342-94d0-e3d855412d2c" className={classes.form}>
      <h1 className="text-center">
        <FormattedMessage id="contact.form.title" />
      </h1>
      <div className={classes.field}>
        <label htmlFor="name">
          <FormattedMessage id="contact.form.name" />
        </label>
        <input name="name" className={classes.input} autoFocus required />
      </div>
      <div className={classes.field}>
        <label htmlFor="phone">
          <FormattedMessage id="contact.form.phone" />
        </label>
        <input name="phone" className={classes.input} type="tel" required />
      </div>
      <div className={classes.field}>
        <label htmlFor="email">
          <FormattedMessage id="contact.form.email" />
        </label>
        <input name="email" className={classes.input} type="email" required />
      </div>
      <div className={classes.field}>
        <label htmlFor="message">
          <FormattedMessage id="contact.form.message" />
        </label>
        <textarea name="message" className={classes.textarea} rows={10} required />
      </div>
      <button className={classes.button}>
        <FormattedMessage id="contact.form.send" />
      </button>
    </form>
  );
};

export default ContactForm;
