import React, { FunctionComponent } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import "./footer.scss";

import silicoLogo from "../../assets/images/silico-logo.svg";
import ContactForm from "./contact-form";

const Footer: FunctionComponent = () => (
  <footer>
    <div className="cs_container">
      <div className="cs_row">
        <div className="cs_col">
          <ContactForm />
        </div>
      </div>
    </div>
    <div className="cs_footer-contact-wrapper">
      <div className="cs_container wide">
        <div className="cs_row cs_bot-margin-m">
          <div className="cs_col logo">
            <img className="cs_footer-logo" src={silicoLogo} alt="Silico logo" />
          </div>
          <div className="cs_col grow cs_bot-margin-m">
            <div className="cs_footer-logo-wrapper">
              <img className="cs_footer-logo" src={silicoLogo} alt="Silico logo" />
            </div>
            <FormattedMessage id="contact.street" />
            <br />
            <FormattedMessage id="contact.city" />
            <br />
            <FormattedMessage id="contact.country" />
          </div>
          <div className="cs_col grow cs_bot-margin-m">
            <a href="tel:+48227738925">+48&nbsp;(0)22&nbsp;773-89-25</a>
            <br />
            <a href="tel:+480227604407">+48&nbsp;(0)22&nbsp;760-44-07</a>
            <br />
            <a href="tel:+480227738955">+48&nbsp;(0)22&nbsp;773-89-55</a>
          </div>
          <div className="cs_col grow cs_bot-margin-s">
            <a href="mailto:biuro@silico.pl">biuro@silico.pl</a>
          </div>
        </div>
        <div className="cs_row">
          <div className="cs_col">
            <p>© SILICO Sp. z o.o. - wszystkie prawa zastrzeżone.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
