import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import Header from "./header/header";
import Footer from "./footer/footer";

import "../main.scss";

const Layout: FunctionComponent = ({ children }) => (
  <div className="layout">
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,700"
        rel="stylesheet preload prefetch"
        as="font"
      />
    </Helmet>
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
