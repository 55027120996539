import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

type Meta =
  | { content: string; name: string; property?: undefined }
  | { content: string; name?: undefined; property: string };

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: Meta[];
}

const SEO: FunctionComponent<SEOProps> = ({ description = "", lang = "en", meta = [], title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          image: ["https://www.silico.pl/static/dd23664350202769ca22037c02f79538/2fe18/silico.webp"],
          "@id": "https://www.silico.pl",
          name: "Silico",
          legalName: "Silico sp. z o.o.",
          description: site.siteMetadata.description,
          address: {
            "@type": "PostalAddress",
            streetAddress: "ul. Zgody 16B",
            addressLocality: "Józefin",
            addressRegion: "mazowieckie",
            postalCode: "05-074",
            addressCountry: "PL",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 52.238296532756095,
            longitude: 21.330668497178596,
          },
          url: "https://www.silico.pl",
          email: "biuro@silico.pl",
          telephone: "+48 22 773 89 25",
          openingHoursSpecification: [
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              opens: "7:00",
              closes: "15:00",
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
